<template>
  <div
    class="flex w-full bg-white h-screen overflow-y-auto items-start make-items-center"
  >
    <!-- From Column -->
    <div
      class="w-full sm:w-full lg:w-1/2 items-center flex justify-center p-8 md:p-16 h-full"
    >
      <div class="max-w-md mx-auto w-full space-y-12">
        <div
          class="flex flex-col sm:flex-row items-center justify-center md:justify-between"
        >
          <Icon class="h-9 w-auto" name="medusa-dist-logo" />
          <span
            class="border-transparent h-4 w-0 border-r sm:h-10 sm:border-brand-ash mx-3"
          ></span>
          <span class="text-lg font-semibold text-black">Change Password</span>
        </div>

        <p class="text-brand-body font-light">
          Changing password for {{ email }}
        </p>

        <form class="w-full space-y-6" @submit.prevent="formcheck">
          <span
            class="text-sm font-normal text-red-400 inline-flex items-center"
            v-if="resetpassword.error.message"
            ><ExclamationCircleIcon class="w-4 h-4" />{{
              resetpassword.error.message
            }}</span
          >
          <div>
            <Input
              v-model:modelValue="resetpassword.password"
              label="New Password"
              desc="Must be atleast 6 characters"
              :inputClass="
                isSubmit && v$.resetpassword.password.$silentErrors.length > 0
                  ? 'error'
                  : ''
              "
              :type="showPassword ? 'text' : 'password'"
              placeholder="Enter your password"
            >
              <!-- <Input v-model:modelValue="user.password" label="New Password" :inputClass="(resetpassword.error.password.message || resetpassword.error.message) ? 'error' : ''" :type="showPassword ? 'text' : 'password'" placeholder="Enter your password"> -->
              <template #extra>
                <span
                  @click="showPassword = !showPassword"
                  class="absolute right-4 top-1/2 -mt-2.5 cursor-pointer text-theme-dark-shade hover:text-theme-dark"
                >
                  <EyeIcon class="w-5 h-5" v-if="showPassword" />
                  <EyeOffIcon class="w-5 h-5" v-else />
                </span>
              </template>
            </Input>
            <span
              class="text-sm text-theme-body font-normal pt-1.5 inline-block"
              v-if="
                !(
                  isSubmit && v$.resetpassword.password.$silentErrors.length > 0
                )
              "
              >Must be at least 8 characters</span
            >
            <span
              v-if="
                isSubmit && v$.resetpassword.password.$silentErrors.length > 0
              "
              class="text-sm font-light text-red-400 py-2"
            >
              <span
                v-if="
                  v$.resetpassword.password.$silentErrors[0].$validator ==
                    'required'
                "
                >Password is required.</span
              >
              <span
                v-else-if="
                  v$.resetpassword.password.$silentErrors[0].$validator ==
                    'minLength'
                "
                >Your password must be atleast 8 characters long.</span
              >
            </span>
          </div>
          <div>
            <Input
              v-model:modelValue="resetpassword.password_confirmation"
              :inputClass="
                isSubmit &&
                v$.resetpassword.password_confirmation.$silentErrors.length > 0
                  ? 'error'
                  : ''
              "
              label="Confirm Password"
              placeholder="Enter your password"
              :type="lookPassword ? 'text' : 'password'"
            >
              <template #extra>
                <span
                  @click="lookPassword = !lookPassword"
                  class="absolute right-4 top-1/2 -mt-2.5 cursor-pointer text-theme-dark-shade hover:text-theme-dark"
                >
                  <EyeIcon class="w-5 h-5" v-if="lookPassword" />
                  <EyeOffIcon class="w-5 h-5" v-else />
                </span>
              </template>
            </Input>
            <span
              v-if="
                isSubmit &&
                  v$.resetpassword.password_confirmation.$silentErrors.length >
                    0
              "
              class="text-sm font-light text-red-400 py-2"
            >
              <span
                v-if="
                  v$.resetpassword.password_confirmation.$silentErrors[0]
                    .$validator == 'required'
                "
                >Confirm password is required.</span
              >
              <span
                v-else-if="
                  v$.resetpassword.password_confirmation.$silentErrors[0]
                    .$validator == 'sameAsPassword'
                "
                >The password confirmation does not match.</span
              >
            </span>
            <!-- <span class="text-sm font-light text-red-400 py-2" v-if="resetpassword.error.password_confirmation.message">{{ resetpassword.error.password_confirmation.message }}</span> -->
            <!-- <span class="text-sm font-light text-red-400 py-2" v-if="resetpassword.password.value != resetpassword.password_confirmation.value">passwords should be same</span> -->
          </div>
          <div>
            <Button
              icon="right-arrow"
              title="Change Password"
              type="submit"
              :loader="resetpassword.loading"
            />
          </div>
          <div>
            <p class="block text-center text-brand-body text-opacity-70">
              Back to
              <router-link
                to="/login"
                class="text-brand-secondary underline font-semibold"
                >Log in</router-link
              >
            </p>
          </div>
        </form>
      </div>
    </div>

    <!-- Info Column -->
    <InfoColumn />
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { reactive, computed } from "vue";
import { required, minLength, sameAs } from "@vuelidate/validators";
import Auth from "@/modules/Auth";
import Input from "../../../components/Input.vue";
import InfoColumn from "../common/InfoColumn.vue";
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/outline";
import { ref } from "vue";
export default {
  components: { Input, EyeIcon, EyeOffIcon, InfoColumn },
  name: "Login",
  data() {
    return {
      v$: useVuelidate(),
      // resetpassword:{
      //   password: '',
      //   password_confirmation: '',
      // },
      email: this.$route.path.split("/")[2],
      isSubmit: false,
    };
  },
  validations() {
    return {
      resetpassword: {
        password: { required, minLength: minLength(8) },
        password_confirmation: {
          required,
          sameAsPassword: sameAs(this.resetpassword.password),
        },
      },
    };
  },

  methods: {
    formcheck() {
      this.isSubmit = true;
      this.v$.$validate();
      if (!this.v$.$error) {
        // alert("Form Valid. Move to next screen");
        this.Passwordreset();
      } else {
        //  alert("Form nOT Valid");
      }
    },
  },

  setup() {
    const showPassword = ref(false);
    const lookPassword = ref(false);
    const { resetpassword, Passwordreset } = Auth();

    return {
      resetpassword,
      Passwordreset,
      showPassword,
      lookPassword,
    };
  },
};
</script>
